import { plainBtnStyles } from '@/buttons/Plain';
import { css } from '@emotion/react';
import { theme } from './styles/GlobalStyles';

const dynamicStyles = css`
    position: relative;

    ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: transparent;
        transition: background-color 0.3s ease-in-out;
    }

    &[aria-current='page'] {
        color: ${theme.colors.blue[300]};

        ::after {
            background-color: ${theme.colors.blue[300]};
        }
    }

    &.current-nav-category,
    &.current-sub-category {
        color: ${theme.colors.blue[200]};

        > svg path {
            fill: ${theme.colors.blue[200]};
        }
    }

    &[aria-expanded='true'] {
        > svg {
            transform: rotate(180deg);
        }
    }

    @media (pointer: fine) {
        :hover {
            color: ${theme.colors.yellow[200]};

            ::after {
                background-color: ${theme.colors.yellow[500]};
            }

            > svg path {
                fill: ${theme.colors.yellow[200]};
            }
        }

        :focus-visible {
            color: ${theme.colors.yellow[200]};

            ::after {
                background-color: ${theme.colors.yellow[500]};
            }

            > svg path {
                fill: ${theme.colors.yellow[200]};
            }
        }

        :active {
            color: ${theme.colors.yellow[700]};

            ::after {
                background-color: ${theme.colors.yellow[700]};
            }

            > svg path {
                fill: ${theme.colors.yellow[700]};
            }
        }
    }

    @media (min-width: 1320px) {
        &.desktop-sub-nav-link {
            :hover {
                color: ${theme.colors.yellow[900]};
                background-color: ${theme.colors.yellow[100]};

                > svg {
                    path {
                        fill: ${theme.colors.yellow[700]};
                    }
                }
            }

            :focus-visible {
                color: ${theme.colors.yellow[900]};
                background-color: ${theme.colors.yellow[100]};

                > svg {
                    path {
                        fill: ${theme.colors.yellow[700]};
                    }
                }
            }

            &[aria-expanded='true'] {
                > svg {
                    transform: rotate(-270deg);
                }
            }
        }
    }
`;

export const navLinkStyles = css`
    ${dynamicStyles};
    text-transform: capitalize;
    color: ${theme.colors.gray[50]};
    transition: color 0.3s ease-in-out;
    margin-bottom: 24px;
    font-weight: 600;
    padding: 6px 4px;
    font-size: 1rem;

    @media (min-width: 1320px) {
        margin-bottom: 0 !important;
        margin-right: 16px;
    }
`;

export const subNavLinkStyles = css`
    ${navLinkStyles};
    transition:
        color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

    @media (min-width: 1320px) {
        color: ${theme.colors.gray[600]};
        padding: 8px 16px;
        font-weight: 400;
        margin: 0;

        ::after {
            content: none;
        }
    }
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};

    > svg {
        margin-left: 10px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${theme.colors.white};
        }
    }

    @media (max-width: 1319px) {
        > svg {
            transform: rotate(-90deg);
        }
    }
`;

export const subNavBtnStyles = css`
    ${plainBtnStyles};
    ${subNavLinkStyles};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    > svg {
        transition: transform 0.3s ease-in-out;
        transform: rotate(-90deg);
        margin-left: 10px;
    }
`;
